import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

function printPatients({
    patients,
    userName,
    facilityName,
    locationName,
    date,
    eventType = 'checked-in',
}) {
    const formattedPatients = map(patients, 'formatted');
    let tableHeader = '';

    tableHeader += '<tr>';
    tableHeader += `<th>Name</th>`;
    tableHeader += `<th>DOB</th>`;
    tableHeader += `<th>Gender</th>`;
    tableHeader += `<th>Address</th>`;
    tableHeader += `<th>Phone</th>`;
    tableHeader += `<th>Check-In Date</th>`;

    if (isEqual(eventType, 'checked-out')) {
        tableHeader += `<th>Check-out Date</th>`;
        tableHeader += `<th>Check-out Destination</th>`;
    }

    tableHeader += '</tr>';

    let tableRows = '';
    forEach(formattedPatients, patient => {
        const formattedDateOfDeath = patient.dateOfDeath
            ? `(${patient.dateOfDeath})`
            : '';
        const checkoutLocation = !isEmpty(patient.checkOutLocation)
            ? patient.checkOutLocation
            : '';

        tableRows += '<tr>';

        tableRows += `<td>${patient.name}</td>`;
        tableRows += `<td>${patient.dobString}</td>`;
        tableRows += `<td>${patient.gender}</td>`;
        tableRows += `<td>${patient.fullAddress}</td>`;
        tableRows += `<td>${patient.phone}</td>`;
        tableRows += `<td>${patient.checkInDate}</td>`;

        if (isEqual(eventType, 'checked-out')) {
            tableRows += `<td>${patient.checkOutDate}</td>`;
            tableRows += `<td>
                            <div>${patient.checkOutType} ${formattedDateOfDeath}</div>
                            <div>${checkoutLocation}</div>
                        </td>`;
        }

        tableRows += '<tr>\n';
    });

    let title = 'Checked In Patients';
    if (isEqual(eventType, 'checked-out')) {
        title = 'Checked Out Patients';
    }

    const printWindow = window.open('', 'PRINT', 'height=600,width=800');
    printWindow.document.write(`
<html>
<head>
<title>
${title}
</title>
<style>

body {
    font-family: sans-serif;
}

.info .item {
    display: flex;
}

.info .item .title {
    width: 150px;
    font-weight: bold;
}

table {
    width: 100%;
}

td {    
    padding: 5px;
    vertical-align: top;
    color: rgb(77, 77, 77);
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0;
    width: 100px;
    line-height: 18px;how to make table cloumns uneven 
}

th {    
    vertical-align: bottom;
    padding: 10px 5px 10px 5px;
    text-align: left;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: 600;
    height: 18px;
    letter-spacing: 0;
    width: 100px;
}

</style>
</head>
<body>

<div class="info">
    <div class="item">
        <div class="title">
            Facility:
        </div>
        <div class="value">
            ${facilityName}
        </div>
    </div>
    <div class="item">
        <div class="title">
            Location:
        </div>
        <div class="value">
            ${locationName}
        </div>
    </div>
</div>

<table>
    ${tableHeader}
    ${tableRows}
</table>

<div class="info">
    <div class="item">
        <div class="title">
            User Name:
        </div>
        <div class="value">
            ${userName}
        </div>
    </div>
    <div class="item">
        <div class="title">
            Date:
        </div>
        <div class="value">
            ${date}
        </div>
    </div>
</div>

</body>
</html>
    `);
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
        printWindow.print();
        printWindow.close();
    }, 10);
}

export default printPatients;
